<template>
    <div class="refund" >
    <Head :type="0" title="退款">
        
    </Head>

<div class="main">
<div class="tips">
    <div class="title">
        温馨提示：
    </div>
    <div class="content">
        
        <p>订单一旦取消，无法恢复</p>
        <!-- <p>2、如遇订单拆分，京券将换成同价值京豆返还</p>
        <p>3、支付券不予返还；支付优惠一 并取消 </p>
        <p>4、订单一旦取消，无法恢复</p>
        <p>5、微信搜一搜订单返现金额会从退款中扣除</p> -->
    </div>
</div>
<ul>
    <li>
        <div class="title">
            退款原因
        </div>
        <div class="content" @click="show=true" style="color:#999;">{{reszon}}</div>
        <img src="../../assets/img/right_gery.png" alt="">
    </li>
    <li>
        <div class="title">联系人</div>
        <div class="content" v-if="$store.state.userDate">{{$store.state.userDate.nickName}}</div>
    </li>
     <li>
        <div class="title">联系方式</div>
        <div class="content" v-if="$store.state.userDate">{{$store.state.userDate.phone}}</div>
    </li>
</ul>
</div>
<div class="footer" @touchmove.prevent>
    <div  class="btn"  @click="tuikuan()"> 申请退款</div>
</div>
<van-action-sheet v-model="show" >
  <div class="box">
      <div class="title">请选择取消订单的原因（必选）</div>
      <div class="choose">
          <div class="choose-item" v-for="item in chooseList" :key="item" @click="reszon=item,show=false">
              <img src="../../assets/roomImg/choose.png" v-if="item!=reszon" alt="">
              <img src="../../assets/chooseRead.png" v-if="item==reszon" alt="">

              <div>{{item}}</div>
          </div>
      </div>
      <div class="bottom" @click="show=false">
          确定
      </div>
  </div>
</van-action-sheet>

    </div>
</template>
<script>
import { getMoney} from '../../api/refund/index'
import Head from '../../components/head/index.vue'
    export default {
        data(){
            return{
                startY:0,
                show:false,
                reszon:"请选择退款原因",
                chooseList:[ '商品无货','发货时间问题','不想要了','商品错选/多选','地址信息填写错误','商品降价','没用/少用/错用优惠'],
                userInfo:null
            }
        },
        beforecreated(){
            if(!this.$route.query.refundId){
                this.$router.back()
            }
        },
            mounted(){
               this.getUser()
               console.log(this.$store.state);
                    this.setRem()
                    window.addEventListener('resize',this.setRem)
                    window.addEventListener("orientationchange", this.setRem);
                   
            },
            components:{Head},
            methods:{
                              
                    tuikuan(){

                        //  reszon:"请选择退款原因",
                         if(this.reszon==="请选择退款原因"){

                             this.$toast('退款原因不能为空')
                            
                                return

                         }

                            let re = {refundReson:this.reszon ,refundTradeNo:Date.now(),status: '0'} //退款原因，退款时间戳
                              try{ 
                                  re.status=this.$route.query.status??'0'
                                  re.orderItemId=this.$route.query.id??this.$route.query.orderItemId??''
                                  
                              }catch{ 
                                  //
                              }
                                
                            let data = { ...this.$route.query,...re}   
                            console.log(this.$route.query);
                // sessionStorage.setItem('status',JSON.stringify({ index:String(this.$route.query.index) ,attribute:this.$route.query.attribute,status:1}))
              getMoney(data).then(res=>{

                           if(res.data.code===0){

                               if(res.data){
                                   this.$toast.success('申请成功')
                                    

                                   this.$router.back()
                               }
                               
                           }else{ 
                               this.$toast(res.data.msg)
                           }
                       })     

                    }
            }
    }
</script>
<style lang="less" scoped>
.refund{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    font-family: PingFangSC-Medium, PingFang SC;
    display: flex;
    flex-direction: column;
    
     .main{

        top: 1.333333rem;
        width: 100%;
      overflow: auto;
      flex: 1;
    
        .tips{
            margin: .2667rem .453333rem 0;
            
            height: 5.066667rem;
            background: #F8F8F8;
            border-radius: .133333rem;
            padding: .48rem .4rem;
            box-sizing: border-box;
            .title{
                font-size:.426667rem;
                font-family: PingFangSC-Medium;
                color: rgba(0, 0, 0, 1);
                text-overflow: ellipsis;
                margin-bottom: .533333rem;
                font-weight: 600;
            }
            .content{
                white-space: pre-wrap;
                font-size: .373333rem;
                font-family: PingFangSC-Regular;
                color: rgba(0, 0, 0, 1);
                text-overflow: ellipsis;
            }
        }
        ul{
           
        li{
            display: flex;
            height: 1.493333rem;
            border-bottom: 1px solid #eee;
            align-items: center;
            padding-right: .4rem;
                font-size: .453333rem;

            .title{
                width: 2.08rem;
                height: .8rem;
                border-right:1px solid rgba(0,0,0,.1) ;
                padding:0 .4rem;
                 display: flex;
                align-items: center;
                justify-content: center;
                
            }
            .content{
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: .266667rem;
            }
            img{
                width: .4rem;
            }
            }
        }

    }
    .footer{
        width: 100%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
        padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
        display: flex;
        align-items: center;
            .btn{
        width: 9.173333rem; 
        height: .96rem;
        background-image: linear-gradient(to right , #FF7200,#FF3C00);
        border-radius: .48rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: .373333rem;
        margin: .266667rem auto;
    }
    }
    .box{
        padding-top:.533333rem ;
         padding-bottom: constant(safe-area-inset-bottom);///兼容 IOS<11.2/
            padding-bottom: env(safe-area-inset-bottom);///兼容 IOS>11.2/
        .title{
            text-align: center;
            height: .586667rem;
            font-size: .426667rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            padding-bottom: .533333rem;
        }
        .choose-item{
            display: flex;
            align-items: center;
            height: 1.493333rem;
            border-bottom: .013333rem solid #eee;
            padding-left: .453333rem;
            img{
                width: .64rem;
                height: .64rem;
                margin-right: .4rem;
            }
            
        }
        .bottom{
            width: 9.173333rem;
            height: .96rem;
            background-image: linear-gradient(to right,#FF7200,#FF3C00);
            border-radius: .48rem;
            margin:.266667rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .373333rem;
            color: white;
        }
    }
    

   
}

</style>